import React, { useContext, useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import store from 'store';
import { useQueryParam, StringParam, useQueryParams } from 'use-query-params';

import { ClientHelmet, EventFrame, Layout } from '../components';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';

const GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM =
    process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM === 'true'
        ? true
        : false;
const IFRAME_NAME = 'main-event-iframe';

const IndexPage = () => {
    const context = useContext(GlobalContext);
    const { eventAuthModule, fetchEvent } = context;
    const [queryParams, setQueryParams] = useQueryParams({
        panoramaName: StringParam,
        action: StringParam,
        boothId: StringParam,
    });

    const handleQueryAction = async () => {
        try {
            if (queryParams.action === 'redirect' && queryParams.boothId && !queryParams.panoramaName) {
                const { panoramaName } = await apiRequester.getBoothRedirectQuery({
                    boothId: queryParams.boothId,
                });
                if (panoramaName) {
                    window.location.href = `?panoramaName=${panoramaName}`;
                }
            }
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        const loggedInUser = store.get('user') as Users.User;
        const accessToken = store.get('token') as string;
        const refreshToken = store.get('refreshToken') as string;
        if (queryParams.action === 'redirect') {
            handleQueryAction();
        } else if (typeof context.eventAuthModule !== 'undefined') {
            // Check if redirection is needed
            const eventType = GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM
                ? 'semi'
                : context.eventAuthModule === null
                ? 'public'
                : 'private';
            if (eventType === 'public') {
                console.log('Loading public event');
                if (!loggedInUser || !accessToken || !refreshToken) {
                    apiRequester
                        .publicSignIn({})
                        .then(loginData => {
                            store.set('token', loginData.token);
                            store.set('refreshToken', loginData.refreshToken);
                            store.set('user', loginData.user);
                            if (typeof window !== 'undefined') window.location.reload();
                        })
                        .catch(handleError);
                    return;
                }
            } else if (eventType === 'semi') {
                console.log('Loading semi-public event');
                if (!loggedInUser || !accessToken || !refreshToken) {
                    console.log('Token not found. Redirecting to register.');
                    navigate(`/register${typeof window !== 'undefined' ? window.location.search : ''}`);
                    return;
                }
            } else if (eventType === 'private') {
                console.log('Loading private event');
                if (!loggedInUser || !accessToken || !refreshToken) {
                    console.log('Token not found. Redirecting to register.');
                    navigate(`/login${typeof window !== 'undefined' ? window.location.search : ''}`);
                    return;
                }
            }

            context.setUser(loggedInUser);
        }
    }, [context.eventAuthModule]);

    useEffect(() => {
        fetchEvent().catch(handleError);
    }, []);

    return context.pubnub ? (
        <PubNubProvider client={context.pubnub}>
            <ClientHelmet />
            <Layout>
                <EventFrame />
            </Layout>
        </PubNubProvider>
    ) : (
        'Loading...'
    );
};

export default IndexPage;
